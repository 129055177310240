<template  lang="pug">
  div
    v-progress-circular(v-if="isLoading" class="d-block mx-auto my-4" :size="40", :width="4", color="primary" indeterminate)
    v-data-table(
      v-else
      :headers="headers"
      :items="history"
      :search="search"
      disable-pagination
      hide-default-footer
      class="elevation-1")
      template( v-slot:item.real_pickup_date="{ item }")
        span(v-if="item.real_pickup_date") {{ $moment(item.real_pickup_date).locale("cs").format('LLL') }}
        span(v-else) nevyzvednuto
      template( v-slot:item.real_return_date="{ item }")
        span(v-if="item.real_return_date") {{ $moment(item.real_return_date).locale("cs").format('LLL') }}
        span(v-else) nevráceno

</template>

<script>
export default {
  name: "ResourceHistory",
  data() {
    return {
      search: "",
      headers: [{ text: 'Žadatel', value: 'applicant_name' },
        { text: 'Projekt', value: 'project_name' },
        { text: 'Vyzvednuto', value: 'real_pickup_date' },
        { text: 'Vráceno', value: 'real_return_date' },
        { text: 'Komentář', value: 'comment' },
     ]
    }
  },
  props: ['isLoading','history'],

  methods: {
  }
}
</script>

<style scoped>

</style>
