<template lang="pug">
  v-main
    v-container
      v-card
        v-tabs(v-model="tab")
          v-tab(v-if="userRole !== 'COMMON'", href="#pending" ) Ke schválení
          v-tab(@change="tab='my'" href="#my")  Moje oprávnění
          v-tab(v-if="userRole !== 'COMMON'", href="#all")  Vše
          v-tab(v-if="userRole !== 'COMMON'", href="#users")  Uživatelé
      v-data-table.elevation-1(
        v-if="tab!=='users'"
        :headers='headers'
        :loading="loading"
        :items='filteredItems'
        :search='search'
        :custom-filter="permissionFilter"
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page='50')
        template(v-slot:top)
          v-toolbar(flat='')
            v-text-field(style='max-width: 300px'
              v-model='search'
              append-icon='mdi-magnify'
              label='Hledat'
              single-line=''
              hide-details='')
            v-spacer
            v-btn(color='primary' :disabled="isUserWaitingForApproval" @click.stop='openCreateDialog')
              | Vytvořit žádost
        template(v-slot:item.approved='{ item }')
          v-icon(v-if="item.approved === null") mdi-help-box
          v-icon(v-else-if="item.approved === false" color='error') mdi-close-box
          v-icon(v-else color='success') mdi-checkbox-marked
        template(v-slot:item.applicant='{ item }')
          | {{item.applicant.fullname}}
        template(v-slot:item.actions='{ item }')
          span(v-if="item.approved === null")
            v-btn(icon color="green")
              v-icon( @click="openResolveDialog(item, true)") mdi-check-decagram
            v-btn(icon color="red")
              v-icon( @click="openResolveDialog(item, false)") mdi-close-octagon-outline
          span(v-else-if="item.approved === true")
            v-btn(icon color="red")
              v-icon( @click="openResolveDialog(item, false, true)") mdi-close-octagon-outline
        template(v-slot:item.insurance_file_url='{ item }')
          v-btn(v-if="item.insurance_file_url" icon color="green" target="_blank" :href="apiUrl + item.insurance_file_url")
            v-icon() mdi-file-download
        template(v-slot:item.expiration_date='{ item }')
          span( v-if="item.expiration_date" :style="{ color: $moment(item.expiration_date) > new Date() ?'#36a436' : '#c74848'}") {{$moment(item.expiration_date).locale("cs").format('LLL') }}

      v-data-table(
        v-else-if="userRole !== 'COMMON' && permissionLevels.length>0"
        sort-by="permission_level"
        sort-desc
        :headers="userTableHeaders"
        :search='search'
        :custom-filter="usersFilter"
        :items="$store.state.users"
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page='50'
      )
        template(v-slot:top)
          v-toolbar(flat='')
            v-text-field(style='max-width: 300px'
              v-model='search'
              append-icon='mdi-magnify'
              label='Hledat'
              single-line=''
              hide-details='')
        template(v-slot:item.permission_level="{ item }")
          | {{permissionLevels.find(x => x.level === item.permission_level)?.name}}
    v-dialog(v-model='createDialog' max-width='500')
      v-card(v-if="userHasFilledRequiredDetails")
        v-card-title.headline Žádost o přidělení oprávnění
        v-card-text
          v-form(ref='createPermissionRequestForm' lazy-validation)
            v-text-field( placeholder="Natáčení filmu Sharknado 6 na FFIMU..." v-model='newRequest.reason' :rules="[(v) => !!v || 'Vyplňte důvod žádosti']" label='Důvod')
            v-select(
              v-model="newRequest.requestedLevel"
              label="Požadovaná úroveň oprávnění"
              :item-text="(itm) => itm.name"
              :item-value="(itm) => itm.level"
              :items="permissionLevels")
            v-alert(class="mb-0"
              v-if="newRequest.requestedLevel > 1"
              dense
              icon="mdi-alert"
              text
              type="warning") Pro udělení tohoto oprávnění je potřeba poskytnout potvrení o pojištění. Vice informací v nápovědě.
            v-file-input(
              label="Pojistná smlouva"
              v-if="newRequest.requestedLevel > 1"
              :rules="[(v) => !!v && newRequest.requestedLevel > 1 || 'Pro lemma nad 100 přiložte smlouvu od pojišťovny']"
              v-model="newRequest.insuranceContract")
            //v-menu(
            //  v-if="newRequest.requestedLevel > 1"
            //  v-model="datepicker2"
            //  :close-on-content-click='false'
            //  :nudge-right='40'
            //  transition='scale-transition'
            //  offset-y=''
            //  min-width='auto')
            //  template(v-slot:activator='{ on, attrs }')
            //    v-text-field(v-model='newRequest.insuranceExpirationDate' label='Datum expirace pojištění' readonly v-bind='attrs' v-on='on')
            //  v-date-picker(v-model='newRequest.insuranceExpirationDate' @input='datepicker2 = false')

        v-divider
        v-card-actions
          v-spacer
          v-btn( @click='createDialog = false')  zavřít
          v-btn( color="primary" :disabled="sending" @click='create')
            v-progress-circular(v-if="sending" class="d-block mx-auto my-4" :size="20", :width="2", indeterminate)
            span(v-else) Odeslat

      v-card(v-else)
        v-card-title.headline Chyba
        v-card-text Pro podání žádosti je nutné vyplnit v uživatelském profilu e-mail, tel. číslo a adresu

    v-dialog(v-model='resolveDialog' max-width='500')
      v-card
        v-card-title.headline
          span(v-if="newResolve.approved") Schválení požadavku
          span(v-else-if="newResolve.alreadyExists") Odebrání oprávnění
          span(v-else) Zamítnutí požadavku
        v-card-text
          v-form(ref='resolvePermissionRequestForm' lazy-validation)
            v-text-field(v-model='newResolve.response',  label='Důvod')
            v-menu(v-if="newResolve.approved" v-model="datepicker" :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='auto')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(v-model='newResolve.expirationDate' label='Datum expirace' readonly v-bind='attrs' v-on='on')
              v-date-picker(v-model='newResolve.expirationDate' @input='datepicker = false')
        v-divider
        v-card-actions
          v-spacer
          v-btn( @click='resolveDialog = false')  zavřít
          template(v-if="!newResolve.alreadyExists")
            v-btn(color="primary" :disabled="sending" @click='resolve')
              v-progress-circular(v-if="sending" class="d-block mx-auto my-4" :size="20", :width="2", indeterminate)
              span(v-else) Odeslat
          template(v-else)
            v-btn(color="primary" :disabled="sending || newResolve.response.length === 0" @click='removePermission')
              v-progress-circular(v-if="sending" class="d-block mx-auto my-4" :size="20", :width="2", indeterminate)
              span(v-else) Odebrat oprávnění

</template>

<script>
import API from '@/model/httpclient'
import {compareString} from "@/utils";

const emptyRequest = () => {
  return {
    requestedLevel: 1,
    reason: '',
    insuranceExpirationDate: null,
    insuranceContract: null,
  }
}
const emptyResolve = (id, approved, alreadyExists = false) => {
  return {
    approved,
    expirationDate: approved ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().substr(0, 10) : null,
    response: '',
    id,
    alreadyExists
  }
}
export default {
  name: 'Permissions',
  data: function () {
    return {
      datepicker: false,
      datepicker2: false,
      newRequest: emptyRequest(),
      newResolve: emptyResolve(true),
      tab: 'pending',
      createDialog: false,
      resolveDialog: false,
      search: '',
      permissions: [],
      permissionLevels: [],
      loading: true,
      sending: false,
      userTableHeaders: [
        {text: 'Jméno', value: 'fullname'},
        {text: 'Platné oprávnění', value: 'permission_level', filterable: false},
        {text: 'Otevřené projekty', value: 'not_closed_projects_count', filterable: false},
      ],
    }
  },
  async created () {
    this.permissionLevels = await API.getPermissionLevels()
    await this.getPermissionRequests()
  },
  computed: {
    isUserWaitingForApproval () {
      return this.permissions.some(itm =>
      {
        //console.log(itm,itm.applicant.id === this.$store.getters.getProfile.id, !itm.approved_by )
        return itm.applicant.id === this.$store.getters.getProfile.id && !itm.approved_by
      })
    },
    filteredItems () {
      return this.permissions.filter(itm => {
        if (this.tab === 'my') return itm.applicant.id === this.$store.getters.getProfile.id
        if (this.tab === 'pending') return itm.approved === null
        return true
      }).map(itm => {
        return {
          approved: itm.approved,
          applicant: itm.applicant,
          approvedBy: itm.approved_by,
          requested_level: itm.requested_level.name,
          created_at: this.$moment(itm.created_at).locale('cs').format('LLL'),
          expiration_date: itm.expiration_date,
          reason: itm.reason,
          response: itm.response,
          id: itm.id,
          insurance_file_url: itm.insurance_file_url
        }
      })
    },
    userRole () {
      return this.$store.getters.getDisplayRole
    },
    userHasFilledRequiredDetails () {
      const profile = this.$store.state.user.profile
      return profile.address !== '' && profile.phone !== '' && profile.email !== ''
    },
    headers () {
      let headers = [
        { text: 'Žadatel', value: 'applicant' },
        { text: 'Level', value: 'requested_level', filterable: false },
        { text: 'Vytvořeno', value: 'created_at', filterable: false },
        { text: 'Důvod', value: 'reason', filterable: false },
        { text: 'Pojištění', value: 'insurance_file_url', filterable: false },
        { text: 'Expirace', value: 'expiration_date', filterable: false },
        { text: 'Odpověď', value: 'response', filterable: false },
        { text: 'Vyhodnotil', value: 'approvedBy', filterable: false },
        { text: 'Výsledek', value: 'approved', filterable: false },
        { text: 'Akce', value: 'actions', sortable: false, filterable: false }
      ]
      if (this.$store.getters.getDisplayRole === 'COMMON') headers = headers.slice(0, -2)
      return headers
    },
    apiUrl () {
      return process.env.VUE_APP_API_URL
    }

  },
  methods: {
    async getPermissionRequests() {
      this.loading = true
      this.permissions = await API.getPermissionRequests()
      this.loading = false
    },
    permissionFilter (value, search) {
        return compareString(search,value.fullname)
    },
    usersFilter (value, search) {
      return compareString(search,value)
    },
    openCreateDialog () {
      if (this.$refs.createPermissionRequestForm) this.$refs.createPermissionRequestForm.resetValidation()
      this.newRequest = emptyRequest()
      this.createDialog = true
    },
    openResolveDialog (itm, approved, alreadyApproved = false) {
      if (this.$refs.resolvePermissionRequestForm) this.$refs.resolvePermissionRequestForm.resetValidation()
      this.newResolve = emptyResolve(itm.id, approved, alreadyApproved)
      this.resolveDialog = true
    },
    async resolve () {
      const date = this.newResolve.expirationDate ? new Date(this.newResolve.expirationDate) : null
      try {
        this.sending = true
        await API.resolvePermissionRequest(this.newResolve.id, date, this.newResolve.approved, this.newResolve.response)
        await this.getPermissionRequests()
        this.resolveDialog = false
        await this.$store.dispatch('notify', {
          type: 'success',
          text: 'Žádost vyřešena'
        })
      } catch (e) {
        console.log(e)
        await this.$store.dispatch('notify', {
          type: 'error',
          text: 'Ukládání žádosti se nezdařilo '
        })
      } finally {
        this.sending = false
      }
    },
    async removePermission () {
      try {
        this.sending = true
        await API.denyPermissionRequest(this.newResolve.id, this.newResolve.response)
        await this.getPermissionRequests()
        this.resolveDialog = false
        await this.$store.dispatch('notify', {
          type: 'success',
          text: 'Oprávnění odebráno'
        })
      } catch (e) {
        console.log(e)
        await this.$store.dispatch('notify', {
          type: 'error',
          text: 'Oprávnění se nepodařilo odebrat '
        })
      } finally {
        this.sending = false
      }
    },

    async loadFile (file) {
      if (!file) return
      const formData = new FormData()
      formData.append('file', file)
      return await API.uploadDocument(formData, file.name)
    },
    async create () {
      if (!this.$refs.createPermissionRequestForm.validate()) return
      try {
        this.sending = true
        const insuranceFileURL = await this.loadFile(this.newRequest.insuranceContract)

        await API.applyPermissionRequest(
            this.newRequest.requestedLevel,
            this.newRequest.reason,
            new Date(this.newRequest.insuranceExpirationDate),
            insuranceFileURL
        )
        await this.getPermissionRequests()
        this.createDialog = false
        await this.$store.dispatch('notify', {
          type: 'success',
          text: 'Žádost vytvořena'
        })
      } catch (e) {
        console.log(e)
        await this.$store.dispatch('notify', {
          type: 'error',
          text: 'Vytváření žádosti selhalo'
        })
      } finally {
        this.sending = false
      }
    }
  }
}
</script>

<style scoped>

</style>
