<template lang="pug">
  v-data-table(:headers="holidayHeaders", :items="holidayData" hide-default-footer disable-pagination)
    template(v-slot:no-data) Výdejář nemá naplánovanou žádnou dovolenou
    template(v-slot:top)
      v-toolbar.mt-2(flat v-if="editable" )
        v-spacer
        v-dialog( v-model='showCalendar' width='290px')
          template(v-slot:activator='{ on, attrs }')
            v-btn(
              color="primary"
              v-bind='attrs'
              v-on='on') Nový záznam
          v-date-picker(first-day-of-week='1' :min='$moment().format()' v-model='datePickerDate'  no-title range scrollable)
            v-spacer
            v-btn(text='' color='primary' @click='addHolidayRecord')
              | OK
    template(v-slot:item.actions='{ item }')
      v-btn.mr-1( icon color="warning" @click.stop="removeHolidayRecord(item)")
        v-icon mdi-delete-circle
    template(v-slot:item.from='{ item }')
      | {{$moment(item.from).locale("cs").format('LL')}}
    template(v-slot:item.to='{ item }')
      | {{$moment(item.to).locale("cs").format('LL')}}

</template>

<script>

export default {
  name: "HolidaysTable.vue",
  props: {
    holidayData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    datePickerDate: [],
    showCalendar: false,
  }),
  computed: {
    holidayHeaders () {
      let headers = [{
            text: 'od',
            value: 'from'
          }, {
            text: 'do',
            value: 'to'
          }
          ]
      if (this.editable){
        headers.push({
          text: 'akce',
          value: 'actions'
        })
      }
      return headers
    }
  },
  methods: {
    addHolidayRecord () {
      const sorted = this.datePickerDate.sort((a, b) => this.$moment(a) - this.$moment(b))
      let from, to
      if (!sorted.length) return
      if (sorted.length === 1) {
        from = to = sorted[0]
      } else {
        from = sorted[0]
        to = sorted[1]
      }
      from = this.$moment(from).toISOString()
      to = this.$moment(to).endOf('day').toISOString()
      this.$store.commit('addHolidayRecord', { from, to })
      this.showCalendar = false
      this.datePickerDate = []
      this.save()
    },
    removeHolidayRecord (record) {
      this.$store.commit('removeHolidayRecord', record)
      this.save()
    },
    async save () {
      this.$emit("save")
    },
  }
}
</script>

<style scoped>

</style>
