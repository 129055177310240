<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="projects"
        :loading="loading"
        @click:row="editProject"
        :search="search"
        :items-per-page='50'
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :custom-filter="filterFn"
        class="elevation-1 pointer-rows"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              style="max-width: 300px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Hledat"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="openCreateDialog">
            Vytvořit projekt
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.finished="{ item }">
        <v-icon>
          {{ item.finished ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editProject(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ $moment(item.created_at).locale("cs").format('LLL') }}
      </template>
      <template v-slot:item.owner="{ item }">
        {{ item.owner.fullname }}
      </template>
      <template v-slot:item.group="{ item }">
        {{ getGroupNameById(item.group) }}
      </template>
    </v-data-table>

    <ProjectEditorModal ref="projectEditorModal"/>
  </div>
</template>

<script>
import ProjectEditorModal from '@/views/projects/ProjectEditorModal'
import {compareString, isObject} from "@/utils";
const emptyProject = () => {
  return {
    name: '',
    description: '',
    active: true
  }
}
export default {
  name: 'ProjectsTable',
  props: ['projects', 'projectGroups', 'filterFn', 'loading'],
  data: () => ({
    search: '',
    loadingProjects: false,
    headers: [
      //set only one column filterable, and filter by other column in custom function v-data-table filtering is buggy
      {text: 'Jméno', value: 'name', filterable: true},
      {text: 'Vytvořeno', value: 'created_at', filterable: false},
      {text: 'Dokončeno', value: 'finished', filterable: false},
      {text: 'Vlastník', value: 'owner', filterable: false},
      {text: 'Skupina', value: 'group', filterable: false}

    ]
  }),
  computed: {},
  components: {
    ProjectEditorModal
  },
  methods: {
    permissionFilter (value, search) {
      const string = isObject(value) ? value.fullname : value
      return compareString(search,string)
    },
    getGroupNameById (id) {
      return this.projectGroups.find(group => group.id === id)?.name
    },
    openCreateDialog () {
      this.$refs.projectEditorModal.openCreateDialog()
    },
    editProject (project) {
      this.$refs.projectEditorModal.editProject(project)
    }
  }
}
</script>

<style scoped>

</style>
