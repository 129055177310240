<template lang="pug">
  v-dialog(v-model="userDialog" scrollable max-width="900px")
    v-card( )
      v-toolbar(color="primary", dark)
        | {{ user.profile.fullname }}
        v-chip.ma-2
          v-icon.mr-2(small) mdi-account-circle-outline
          | {{ currentUserRole }}
        v-spacer
        v-btn(icon, :loading="saveUserLoading" title="Uložit úpravy"  @click="save")
          v-icon mdi-content-save
        v-btn(icon, @click="userDialog = false")
          v-icon mdi-close
      v-card-text.pa-0
        v-container
          v-tabs(v-model="tab" v-if="role_display !== 'COMMON'")
            v-tab Údaje
            v-tab(v-if="role_display !== 'COMMON'") Dostupnost
            v-tab(v-if="role_display !== 'COMMON'") Dovolená
          v-tabs-items(v-model="tab")
            v-tab-item
              v-form.mt-2
                v-select(v-if="currentUserRole !== 'COMMON'" v-model='role_display' :items='role' label='Zobrazit GUI jako')
                v-text-field(v-model='email' label='E-mail' required='')
                v-text-field(label='Telefon' v-model='phone')
                v-textarea(name='Adresa' label='Adresa' v-model='address' hint='Adresa')
                v-text-field(v-if="role_display !== 'COMMON'" label='Místnost' v-model='room')
                v-checkbox.ml-2(v-if="role_display !== 'COMMON'" label='Zasílat upozorněni o žádostech o oprávnění' v-model='send_notification_on_permission_request')
                v-checkbox.ml-2(v-if="role_display !== 'COMMON'" label='Zasílat upozorněni o žádostech o schválení rezervace' v-model='send_notification_on_reservation_request')
            v-tab-item
              v-sheet.mt-2(rounded)
                w-h-table(:data='user.profile.calendar_data' editable="true" @change="save")
            v-tab-item
              holidays-table(:holiday-data="user.profile.holidays" :editable="true" @save="save")


</template>
<script>
import { createHelpers } from 'vuex-map-fields'
import { USER_UPDATE } from '@/store/actions/user'
import WHTable from '@/components/WHTable'
import HolidaysTable from "@/components/HolidaysTable";

const { mapFields } = createHelpers({
  getterType: 'getUserField',
  mutationType: 'updateUserField'
})
export default {
  name: 'user',
  components: {WHTable,HolidaysTable},
  data: () => ({
    userDialog: false,
    tab: 0,
    saveUserLoading: false,
  }),
  computed: {
    currentUserRole () {
      return this.$store.getters.getRole
    },
    user () {
      return this.$store.state.user
    },
    ...mapFields([
      'profile.address',
      'profile.phone',
      'profile.email',
      'profile.role_display',
      'profile.role',
      'profile.room',
      'profile.send_notification_on_permission_request',
      'profile.send_notification_on_reservation_request'
    ]),
    role () {
      return [
        {
          text: 'Admin',
          value: 'ADMIN',
          disabled: this.currentUserRole !== 'ADMIN'
        },
        { text: 'Běžný  uživatel', value: 'COMMON' },
        { text: 'Výdejář', value: 'PROVIDER' }
      ]
    }
  },
  methods: {
    async save () {
      this.saveUserLoading = true
      await this.$store.dispatch(USER_UPDATE)
      this.saveUserLoading = false
    },
    show () {
      this.userDialog = true
    },
  }
}
</script>
