<template>
  <v-main>
    <v-container>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab> Moje projekty</v-tab>
          <v-tab> Všechny projekty</v-tab>
          <v-tab> Skupiny projektů</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!--MY PROJECTS-->
          <v-tab-item>
            <projects-table
                :loading="loading"
                :projects.sync="myProjects"
                :project-groups="projectGroups"
                :filterFn="myProjectsFilter"
            />
          </v-tab-item>
          <!--ALL PROJECTS-->
          <v-tab-item>
            <projects-table
                :loading="loading"
                :projects.sync="projects"
                :project-groups="projectGroups"
                :filterFn="allProjectsFilter"

            />
          </v-tab-item>
          <!--PROJECT GROUP-->
          <v-tab-item>
            <ProjectGroups :project-groups.sync="projectGroups"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

    </v-container>
  </v-main>
</template>

<script>
import API from '@/model/httpclient'
import ProjectGroups from '@/views/projects/ProjectGroupsTable'
import ProjectsTable from '@/views/projects/ProjectsTable'
import {compareString} from "@/utils";

export default {
  components: {ProjectsTable, ProjectGroups},
  data: () => ({
    tab: null,
    loading: false,
    projectGroups: [],
    loadingProjects: false
  }),
  computed: {
    projects() {
      return this.$store.state.projects
    },
    myProjects() {
      return this.$store.getters.myProjects
    }
  },

  watch: {},

  async created() {
    this.loading = true
    await this.$store.dispatch('getProjects')
    this.projectGroups = await API.getProjectGroups()
    this.loading = false
  },

  methods: {
    myProjectsFilter(value, search, item) {
      return compareString(search,[item.name, this.getGroupNameById(item.group) ])
    },
    allProjectsFilter(value, search, item) {
      return compareString(search, [item.name, this.getGroupNameById(item.group), item.owner.fullname])
    },
    getGroupNameById(id) {
      return this.projectGroups.find(group => group.id === id)?.name
    },
  }
}
</script>
