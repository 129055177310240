export const compareString = (query, source) => {

    function removeDiacritics(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    if (!Array.isArray(source)) source = [source]
    const normalizedQuery = removeDiacritics(query.toLowerCase());

    return source.some((item) => removeDiacritics(item.toString().toLowerCase()).includes(normalizedQuery))

}

export const isObject = (value) => {
    return typeof value === 'object' && value !== null
}

