<template lang="pug">
  div
    v-data-table(
       :headers="headers"
       :items="data"
       sort-by="dow"
       disable-pagination
       hide-default-footer
    )
      template(v-slot:item.dow="{ item }")
        | {{dict[item.dow]}}
      template(v-slot:item.del="{ item, index }")
        v-btn.mr-1.text-right( icon color="warning" @click.stop="removeRecord(item)")
          v-icon mdi-delete-circle
      template(v-if="editable" v-slot:body.append)
        tr
          td
            v-autocomplete.pr-2(:items='Object.values(dict).map((key, index) => ({text: key, value: index+1}))'
              v-model="newItem.dow"
              color='white'
              label='Den'

            )
          td
            v-autocomplete.pr-2(:items='availableTimes()'
              auto-select-first
              v-model="newItem.start"
              color='white'
              label='Od'
              )

          td
            v-autocomplete.pr-2(:items='availableTimes()'
              v-model="newItem.end"
              color='white'
              label='Do'
            )

          td
            v-btn.mr-1.text-right( :disabled="!newItem.start || !newItem.end || !newItem.dow" icon color="success" @click.stop="addRecord")
              v-icon mdi-plus-circle

</template>

<script>
const newItemTemplate = {
      dow: 1,
      start: "13:00",
      end: "13:00"
    }

export default {
  name: "WHTable.vue",
  props: ['data','editable'],
  data: () => {
   return {
     dict: {
       1: "Po",
       2: "Út",
       3: "St",
       4: "Čt",
       5: "Pá"
     },
     newItem: {...newItemTemplate},
     availableTimes() {
       let times = [];
       let startHour = 8;
       let endHour = 17;
       let interval = 15;
       for (let hour = startHour; hour <= endHour; hour++) {
         for (let minute = 0; minute < 60; minute += interval) {
           let time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
           times.push(time);
         }
       }
       return times
     }
   }

  },
  computed: {
    headers() {
      let hdr = [
        {
          text: "den",
          value: "dow",
        },
        {
          text: "od",
          value: "start",
        },
        {
          text: "do",
          value: "end",
        },

      ]
      if (this.editable) hdr.push({
        text: "akce",
        value: "del",

      })
      return hdr
    }
  },
  methods: {
    addRecord() {
      this.$store.commit('addAvailabilityRecord', this.newItem)
      this.newItem = {...newItemTemplate}
      this.$emit('change')
    },
    removeRecord(record) {
      this.$store.commit('removeAvailabilityRecord', record)
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>
