<template>
  <v-app>
    <lazy-background style="position: fixed;" src="bg.jpg" />
    <v-overlay z-index="0"/>

    <navbar v-if="$route.name !== 'Login'" />
    <transition
      mode="out-in"
      name="fade"
    >
    <router-view/>
    </transition>
    <toaster/>
  </v-app>
</template>

<script>
import Navbar from './views/Navbar'
import Toaster from './components/Toaster'
export default {
  components: {
    Navbar,
    Toaster
  },
  created: function () {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch("initApp")
    }
  }
}
</script>

<style lang="scss">
 @import "./styles/global.scss";

</style>
